<template>
  <div class="wrap">
    <Navbar :title="$t('newAdd.Email')" />
    <div class="content">
      <div class="email">
        <div class="label">{{ $t('newAdd.Email') }}</div>
        <Field v-model="emailValue" :placeholder="$t('email.placeholder')" />
      </div>
      <div class="btn" @click="onSubmit">{{ $t('My.PersonalInformation.Name.Confirm') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Field, Form, Button } from "vant";
import Navbar from "@/components/JNav";
import { $post, emailRule } from "@/utils";
export default {
  components: {
    Navbar,
    Field,
    Form,
    Button,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async onSubmit() {
      if (!this.email) return this.$toast(this.$t('email.placeholder'));
      if(!emailRule.test(this.email))return this.$toast(this.$t('emial.hintCorrect'));
      try {
        const res = await $post("/users/email/bind", {
          email: this.email,
        });
        this.$store.dispatch("getUserInfo");
        this.$router.go(-1);
      } catch (error) {
        this.$toast(error.msg)
       }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    emailValue: {
      get() {
        return this.email || this.userInfo.email;
      },
      set(val) {
        this.email = val;
        return val;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;

  .content {
    padding: calc(15rem / 16);

    .email {
      .label {
        font-size: calc(18rem / 16);
        font-weight: 500;
        color: var(--light);
        margin-bottom: calc(15rem / 16);
      }

      /deep/ .van-cell {
        background-color: #132235;
        line-height: calc(30rem / 16);

        input {
          color: var(--light);
          font-size: calc(16rem / 16);
        }

        input::placeholder {
          color: var(--colors);
        }
      }
    }

    .btn {
      background-image: linear-gradient(179deg, #13a2ba, #087c95);
      border: none;
      height: calc(54rem / 16);
      font-size: calc(18rem / 16);
      font-weight: 400;
      color: var(--light);
      border-radius: calc(10rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: calc(30rem / 16);
    }
  }

}
</style>